<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card :title="promptName">

      <!-- <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
        <span>User record with id: {{ $route.params.userId }} not found. </span>
        <span>
          <span>Check </span><router-link :to="{name:'page-user-list'}" class="text-inherit underline">All Users</router-link>
        </span>
      </vs-alert> -->

      <!-- Avatar -->
        <div class="vx-row">

          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col" style="height: 56px;width: 80px !important;">
            <div class="img-container mb-4">
              <img :src="formData['image_url'] || '/75x75.png'" class="rounded w-full" @error="imageLoadError"/>
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">Name</td>
                <td>{{ formData['displayName'] }}</td>
              </tr>
              <tr hidden>
                <td class="font-semibold">Date of birth</td>
                <td>{{ dob_model }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Email</td>
                <td>{{ formData['email'] }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">Status</td>
                <td>{{ status_model }}</td>
              </tr>
              <tr hidden>
                <td class="font-semibold">Weight</td>
                <td>{{ formData['weight'] }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Country</td>
                <td>{{ country_model }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 2 -->
          <div class="vx-col w-full flex" id="account-manage-buttons">
            <vs-button icon-pack="feather" icon="icon-edit" class="mr-4" :to="{name: 'Profile', params: { id: $route.params.id }}">Edit</vs-button>
          </div>

        </div>

    </vx-card>
</template>

<script>
// import moduleEmployee          from '@/store/country/moduleEmployee.js'

export default {
  data () {
    return {
      promptName    :'My Profile',
      formData      : {},
      interests     :['1','2']
    }
  },
  components: {

  },
  computed: {
    employee ()     { 
      if('my_profile' == this.$route.params.id){
        return this.$store.state.AppActiveUser
      }else{
        this.$vs.loading()
        return this.$store.getters['employee/getEmployee'](this.$route.params.id).then(() => { this.$vs.loading.close() })
      }
    },
    countries () { 
      return this.$store.getters['country/queriedCountries']
    },
    status_model: {
      get () {
        return this.formData['status']=='1'?'Active':'In Active'
      }
    },
    dob_model: {
      get () {
        const d = new Date(this.formData['date_of_birth'])
        return d.toDateString();
      }
    },
    country_model: {
      get () {
        return this.$store.getters['country/getCountryName'](this.formData['country_id'])
      }
    },
  },
  methods: {
     imageLoadError () {
       this.formData['image_url'] = '/75x75.png'
    },
    confirmDeleteRecord () {

    }
  },
  created () {
    this.formData = Object.assign({}, this.employee)
    // this.$store.registerModule('employee', moduleEmployee)
    if(this.countries.length==0){
      this.$store.dispatch('country/fetchCountries')
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule('employee')
  }
}
</script>
